<script>
export default {
    data() {
        return {
            showMenu: false,
            showAccount: true,
            currentLanguage: 'gb',
            languageName: ['gb', 'vi', 'kr', 'jp', 'cn', 'in'],
            lan: this.$i18n.locale,
            flag: null,
        };
    },
    computed: {
        languages() {
            return [
                {
                    // eslint-disable-next-line global-require
                    flag: require('@/assets/images/lang/gb.png'),
                    language: 'en',
                },
                {
                    // eslint-disable-next-line global-require
                    flag: require('@/assets/images/lang/vi.png'),
                    language: 'vi',
                },
            ];
        },
        languageActive() {
            const value = this.languages.find(
                (x) => x.language === this.$i18n.locale,
            );
            if (!value) {
                this.setLanguage(
                    this.languages[0].language,
                    this.languages[0].title,
                    this.languages[0].flag,
                );
                return this.languages[0];
            }
            return value;
        },
    },
    watch: {
        showMenu(newVal) {
            if (newVal) document.documentElement.style.overflow = 'hidden';
            else document.documentElement.style.overflow = 'auto';
        },
        $route(to, from) {
            if (to.name !== from.name) {
                if (window.innerWidth <= 991) {
                    this.showMenu = false;
                }
            }
            if (to.name === 'Account') {
                this.showAccount = false;
            } else {
                this.showAccount = true;
            }
        },
    },
    async mounted() {
        if (this.$route.name === 'Account') {
            this.showAccount = false;
        } else {
            this.showAccount = true;
        }
    },
    methods: {
        logout() {
            this.$store.commit('auth/LOGOUT_SUCCESS');
        },
        setLanguage(locale, flag) {
            this.lan = locale;
            this.flag = flag;
            this.$i18n.locale = locale;
            window.$cookies.set('language', locale);
            // this.$store.dispatch('language/changeLanguage', locale)
            // this.$store.dispatch('changeLanguage', locale);
            const { id, type } = this.$route.params;
            this.$router.push({
                name: 'All',
                params: {
                    type: type || null,
                    locale,
                    id: id || null,
                },
            });

            setTimeout(() => {
                window.location.reload();
            }, 200);
        },
    },
};
</script>
<template>
    <header id="top-bar">
        <b-container class="header-container desktop">
            <div id="menu">
                <div class="nav-logo">
                    <img src="~@/assets/images/logo/logo.png" alt="" />
                </div>
                <div class="nav-menu">
                    <router-link
                        :to="{ name: 'Blog', params: { locale: $i18n.locale } }"
                        class="menu-item"
                    >
                        <div class="icons">
                            <img src="~@/assets/images/icons/shop.png" alt="" />
                        </div>
                        <div class="text">Blog</div>
                    </router-link>
                    <div class="lang-menu">
                        <b-nav-item-dropdown
                            left
                            class="animated animate__zoomIn launch-app menu-lang"
                            no-caret
                            menu-class="custom-dropdown"
                        >
                            <template v-slot:button-content>
                                <img
                                    :src="languageActive.flag"
                                    alt="flag_active"
                                    width="100%"
                                    height="100%"
                                />
                            </template>
                            <b-dropdown-item
                                v-for="(lang, i) in languages"
                                :key="`Lang${i}`"
                                :value="lang"
                                @click="setLanguage(lang.language, lang.flag)"
                                :class="{
                                    active:
                                        languageActive.language ===
                                        lang.language,
                                }"
                                ><img
                                    :src="`${lang.flag}`"
                                    alt=""
                                    width="100%"
                                    height="100%"
                            /></b-dropdown-item>
                        </b-nav-item-dropdown>
                    </div>
                </div>
            </div>
        </b-container>
        <b-container class="header-container mobile">
            <div id="nav-mobile">
                <div class="nav-logo">
                    <img src="~@/assets/images/logo/logo.png" alt="" />
                </div>

                <div class="toggle-menu-mobile">
                    <b-button @click="showMenu = !showMenu">
                        <img
                            src="~@/assets/images/icons/menu.png"
                            alt=""
                            v-if="!showMenu"
                        />
                        <img
                            src="~@/assets/images/icons/close.png"
                            alt=""
                            v-if="showMenu"
                        />
                    </b-button>
                </div>
                <div class="sidebar-menu" v-if="showMenu">
                    <div class="nav-menu">
                        <router-link
                            :to="{
                                name: 'Blog',
                                params: { locale: $i18n.locale },
                            }"
                            class="menu-item"
                        >
                            <div class="icons">
                                <img
                                    src="~@/assets/images/icons/shop.png"
                                    alt=""
                                />
                            </div>
                            <div class="text">Blog</div>
                        </router-link>
                        <div class="lang-menu-mb">
                            <b-nav-item-dropdown
                                left
                                class="animated animate__zoomIn launch-app menu-lang"
                                no-caret
                                menu-class="custom-dropdown"
                            >
                                <template v-slot:button-content>
                                    <img
                                        :src="languageActive.flag"
                                        alt="flag_active"
                                        width="100%"
                                        height="100%"
                                    />
                                </template>
                                <b-dropdown-item
                                    v-for="(lang, i) in languages"
                                    :key="`Lang${i}`"
                                    :value="lang"
                                    @click="
                                        setLanguage(lang.language, lang.flag)
                                    "
                                    :class="{
                                        active:
                                            languageActive.language ===
                                            lang.language,
                                    }"
                                    ><img
                                        :src="`${lang.flag}`"
                                        alt=""
                                        width="100%"
                                        height="100%"
                                /></b-dropdown-item>
                            </b-nav-item-dropdown>
                        </div>
                    </div>
                </div>
                <div
                    class="back-drop"
                    v-if="showMenu"
                    @click="showMenu = !showMenu"
                ></div>
            </div>
        </b-container>
    </header>
</template>
<style lang="scss">
$top-bar-height: 90px;
$top-bar-height-mb: 60px;
$side-bar-width: 300px;
$top-bar-color: linear-gradient(180deg, #00172a 0%, #00000b 100%);
$top-text-color: #ffffff;
$top-text-active: '';
.desktop,
.mobile {
    display: none;
}
@media (min-width: 768px) {
    .desktop {
        display: block;
    }
}
@media (max-width: 768px) {
    .mobile {
        display: block;
    }
}
#top-bar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background: $top-bar-color;
    height: $top-bar-height;
    width: 100%;
    box-shadow: 5px 0px 15px 5px rgba(0, 0, 0, 0.5);
    @media (max-width: 991px) {
        height: $top-bar-height-mb;
    }
    .login {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        height: 45px;
        text-decoration: none;
        cursor: pointer;
        margin: auto 0 auto 15px;
        background: cyan;
        clip-path: polygon(
            25% 0%,
            75% 0%,
            100% 50%,
            75% 100%,
            25% 100%,
            0% 50%
        );

        .icons {
            width: 35px;
            height: 35px;
            margin: auto 5px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .text {
            color: black;
            font-weight: 900;
            font-size: 20px;
            text-transform: capitalize;
        }
        @media screen and (max-width: 767px) {
            display: none !important;
        }
    }
}

.desktop {
    &.header-container {
        @media (max-width: 1399px) {
            max-width: 100%;
        }
        #menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $top-bar-height;
            font-family: 'HHBI';
            .nav-logo {
                width: fit-content;
                max-width: 250px;
                min-width: 120px;
                height: 100%;
                padding: 5px;
                img {
                    height: 100%;
                    width: auto;
                }
            }
            .nav-menu {
                flex-grow: 1;
                flex: 1 1 auto;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 0 5vw;
                .menu-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    margin: 0 15px;
                    flex: 1;
                    max-width: 200px;
                    text-decoration: none;
                    cursor: pointer;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        min-width: 35px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 21px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                    &.active {
                        // clip-path: polygon(25% 0%, 100% 0%, 70% 100%, 0 100%);
                        background: linear-gradient(
                            0deg,
                            #02ffff -60%,
                            #001326 100%
                        );
                        &.dashboard {
                            // min-width: 250px;
                        }
                        .icons {
                            margin-left: -5px;
                        }
                    }
                }
            }
            .nav-user {
                width: fit-content;
                max-width: 550px;
                min-width: 120px;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                .account {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    text-decoration: none;
                    cursor: pointer;
                    margin: auto 0 auto 15px;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 21px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }
                .balances {
                    width: 250px;
                    background: url('~@/assets/images/button/balance.png');
                    background-size: 100%100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 0 5px;
                    .icons {
                        flex: 0 0 27px;
                        width: 27px;
                        height: 27px;
                        background: black;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .amount {
                        color: #fff;
                        font-size: 17px;
                    }
                    .buy-btn {
                        flex: 0 0 70px;
                        background: url('~@/assets/images/button/buy.png');
                        background-size: 100%100%;
                        width: 70px;
                        color: black;
                        font-weight: 900;
                        font-size: 20px;
                        padding: 0;
                        height: calc(100% - 5px);
                        outline: none !important;
                        border: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            @media (max-width: 1200px) {
                .nav-logo {
                    display: flex;
                    align-items: center;
                    min-width: unset;
                    max-width: unset;
                    img {
                        width: 70px;
                        height: auto;
                    }
                }
                .nav-menu {
                    padding: 0 20px;
                    .menu-item {
                        max-width: 175px;
                        .text {
                            font-size: 16px;
                        }
                    }
                }
                .nav-user {
                    width: auto;
                    min-width: unset;
                    .balances {
                        width: auto;
                        .amount {
                            font-size: 15px;
                            padding: 0 7px;
                        }
                        .buy-btn {
                        }
                    }
                    .account {
                        .text {
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 991px) {
                height: 60px;
            }
        }
        .lang-menu {
            display: flex;
            align-items: center;
            li::marker {
                content: '';
            }
            .menu-lang {
                img {
                    width: 35px;
                    height: auto;
                }
            }
            .custom-dropdown {
                top: 15px !important;
                left: -10px !important;
                width: 100px;
                padding: 15px 0;
                border-radius: 2px;
                border: 0;
                background-color: #0f233b !important;
                text-align: center;
                min-width: inherit;
                .dropdown-item {
                    padding: 0.5rem 1rem !important;
                }
            }
            .dropdown-toggle::after {
                display: none;
            }
        }
    }
}

.mobile {
    &.header-container {
        @media (max-width: 1399px) {
            max-width: 100%;
        }
        #nav-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $top-bar-height-mb;
            font-family: 'HHBI';
            .nav-logo {
                display: flex;
                align-items: center;
                min-width: unset;
                max-width: unset;
                img {
                    width: 70px;
                    height: auto;
                }
            }

            .nav-user {
                width: auto;
                min-width: unset;
                max-width: 550px;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                &.topbar-nv {
                    @media (max-width: 380px) {
                        display: none !important;
                    }
                    @media screen and (max-width: 767px) {
                        .account {
                            display: none !important;
                        }
                    }
                }
                .account {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    text-decoration: none;
                    cursor: pointer;
                    margin: auto 0 auto 15px;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 15px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }
                .balances {
                    width: auto;
                    background: url('~@/assets/images/button/balance.png');
                    background-size: 100%100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 0 5px;
                    .icons {
                        flex: 0 0 27px;
                        width: 27px;
                        height: 27px;
                        background: black;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .amount {
                        color: #fff;
                        font-size: 15px;
                        padding: 0 7px;
                    }
                    .buy-btn {
                        flex: 0 0 70px;
                        background: url('~@/assets/images/button/buy.png');
                        background-size: 100%100%;
                        width: 70px;
                        color: black;
                        font-weight: 900;
                        font-size: 15px;
                        padding: 0;
                        height: calc(100% - 5px);
                        outline: none !important;
                        border: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        .toggle-menu-mobile {
            button {
                background: #06284e;
                height: 45px;
                width: 45px;
                border: 0;
                padding: 0;
                img {
                    height: 30px;
                    width: auto;
                }
            }
        }
        .lang-menu-mb {
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            li::marker {
                content: '';
            }
            .menu-lang {
                img {
                    width: 50px;
                    height: auto;
                }
            }
            .custom-dropdown {
                top: 15px !important;
                left: -10px !important;
                width: 100px;
                padding: 15px 0;
                border-radius: 2px;
                border: 0;
                background-color: #0f233b !important;
                text-align: center;
                min-width: inherit;
                .dropdown-item {
                    padding: 0.5rem 1rem !important;
                }
            }
            .dropdown-toggle::after {
                display: none;
            }
        }
    }
    .sidebar-menu {
        position: fixed;
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: $side-bar-width;
        background: #00000b;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 2300;
        overflow-x: hidden;
        overflow-y: auto;
        .nav-menu {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            .menu-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 50px;
                text-decoration: none;
                padding: 0 15px;
                margin: 5px auto;
                cursor: pointer;
                .icons {
                    width: 30px;
                    height: 30px;
                    margin: auto 10px;
                    min-width: 30px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 17px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }
                &.active {
                    background: url('~@/assets/images/button/active-menu.jpg');
                }
            }
        }
        .break-point {
            background: #00ffffa4;
            height: 3px;
            width: 100%;
            margin: 20px 0;
        }
        .nav-user {
            width: 100% !important;
            justify-content: flex-start !important;
            flex-direction: column;
            .balances,
            .account {
                width: 100%;
                margin: 10px auto !important;
            }
            .account {
                justify-content: flex-start !important;
                padding: 0 15px;
                .icons {
                    margin: 0 10px !important;
                    padding: 3px;
                    img {
                        height: auto !important;
                    }
                }
            }
        }
        @media (max-width: 425px) {
            width: 100%;
            padding-top: 5vh;
        }
        .login-2 {
            margin: 15px auto 0 !important;
            width: 100%;
            max-width: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 120px;
            height: 45px;
            text-decoration: none;
            cursor: pointer;
            margin: auto 0 auto 15px;
            background: cyan;
            clip-path: polygon(
                25% 0%,
                75% 0%,
                100% 50%,
                75% 100%,
                25% 100%,
                0% 50%
            );

            .icons {
                width: 35px;
                height: 35px;
                margin: auto 5px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                color: black;
                font-weight: 900;
                font-size: 20px;
                text-transform: capitalize;
            }
            @media (min-width: 768px) {
                display: none;
            }
        }
    }
    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
    }
}
</style>
